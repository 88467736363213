import {
  Box,
  ButtonGroup,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment as MuiInputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core"
import Layout from "../components/Layout"
import ListingPreview from "../components/Listing"
import styled from "styled-components"
import { useEffect, useMemo, useState } from "react"
import { graphql } from "gatsby"
import { ReactComponent as Beds } from "../icons/beds.svg"
import { ReactComponent as Bath } from "../icons/bath.svg"
import { ReactComponent as LeftArrow } from "../icons/left-arrow-sm.svg"
import { ReactComponent as RightArrow } from "../icons/right-arrow-sm.svg"
import formatPrice from "../util/formatPrice"
import _, { isNil } from "lodash"
import listingsPath from "../util/listingsPath"

const ListingsWrapper = styled(Box)`
  position: relative;
  margin-top: 5px;
  flex: 1;
  &::before {
    content: "";
    background: linear-gradient(to right, #0077bb 0%, #005193 100%);
    height: 5px;
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    transform: translateY(-100%);
  }
`

const InputAdornment = styled(MuiInputAdornment)`
  ${({ theme }) => `
  height:100%;
  max-width:2rem;
  svg {
    width:100%; 
    height:100%;
    g {
      stroke: ${theme.palette.secondary.main};
    }
  }
`}
`

const getQueryParam = (param) => {
  if (typeof window === `undefined`) {
    return undefined
  }
  const p = new URLSearchParams(window.location.search).get(param)
  return p === null ? `` : p
}

const filterValue = (f, value) => {
  if (typeof f === `undefined` || !f || !value) {
    return true
  }
  if (typeof f !== `object`) {
    return f === value
  }
  return Object.keys(f).reduce((curr, verb) => {
    if (!curr) {
      return false
    }
    if (f[verb] == null || f[verb] === undefined || f[verb] === ``) {
      return true
    }
    if (verb === `has`) {
      return value.includes(f[verb])
    }
    if (verb === `eq`) {
      return value.toLowerCase() === f[verb].toLowerCase()
    }
    if (verb === `gte`) {
      return parseInt(value) >= f[verb]
    }
    if (verb === `lte`) {
      return parseInt(value) <= f[verb]
    }
    if (verb === `nin`) {
      return !f[verb].includes(value)
    }
    return curr
  }, true)
}

const Listings = ({
  data: {
    listings: { nodes: allListings },
    locations: { nodes: locations },
  },
  pageContext: { title, type, location },
}) => {
  const limit = 9
  const flattenedListings = allListings.map((l) => ({
    ...l,
    location: !isNil(l.location) ? l.location?.name.toLowerCase() : ``,
  }))
  const [totalCount, setTotalCount] = useState(allListings.length)
  const [page, setPage] = useState(parseInt(getQueryParam(`p`) || 0))
  const initialFilterValue = {
    bedrooms: { gte: parseInt(getQueryParam(`bedrooms`)) || 1 },
    bathrooms: { gte: parseInt(getQueryParam(`bathrooms`)) || 1 },
    price: {
      gte: getQueryParam(`minPrice`),
      lte: getQueryParam(`maxPrice`),
    },
    location: location ? { eq: location } : {},
    status: { eq: `` },
    soldPrice: { gte: 0 },
  }
  const [filter, setFilter] = useState(
    typeof window !== `undefined` && localStorage.getItem(`filter`)
      ? {
          ...JSON.parse(localStorage.getItem(`filter`)),
          location: location ? { eq: location } : {},
        }
      : initialFilterValue
  )
  const [listings, setListings] = useState(flattenedListings.slice(page, limit))
  const resetFilter = () => {
    setFilter(initialFilterValue)
  }
  useEffect(() => {
    const newListings = [
      ...flattenedListings.filter(
        (listing) =>
          Object.keys(filter).filter((k) => {
            return filterValue(filter[k], listing[k])
          }).length === Object.keys(filter).length
      ),
    ]

    setListings(newListings.slice(page * limit, page * limit + limit))
    setTotalCount(newListings.length)
    window.scrollTo(0, 0)
    const params = {}
    if (page > 0) {
      params.p = page
    }
    window.localStorage.setItem(`filter`, JSON.stringify(filter))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page])
  const handleChange = (e, verb) => {
    window.dataLayer?.push({
      event: `search`,
      value: `${e.target.name} ${verb} ${e.target.value}`,
    })
    setFilter({
      ...filter,
      [e.target.name]: {
        ...filter[e.target.name],
        [verb]: e.target.value,
      },
    })
  }

  const shouldShowFilterReset = useMemo(() => {
    return !_.isEqual(initialFilterValue, filter)
  }, [filter, initialFilterValue])

  const FormInput = ({ children, ...other }) => (
    <Grid item xs={12} md {...other}>
      {children}
    </Grid>
  )

  const lowOptions = useMemo(() => {
    const options = []
    let price = 100000
    let priceLimit = 2000000
    let inc = 100000
    const rent = type === `For Rent`
    if (rent) {
      price = 500
      priceLimit = 4000
      inc = 500
    }
    options.push({
      label: `Min`,
      value: undefined,
    })
    while (price <= priceLimit) {
      options.push({
        label: formatPrice(price),
        value: price,
      })
      price += inc
    }
    return options
  }, [type])

  const highOptions = useMemo(() => {
    const options = []
    let price = 300000
    let priceLimit = 2000000
    let inc = 100000
    const rent = type === `For Rent`
    if (rent) {
      price = 1000
      priceLimit = 4000
      inc = 500
    }
    while (price <= priceLimit) {
      options.push({
        label: formatPrice(price),
        value: price,
      })
      price += inc
    }
    options.push({
      value: undefined,
      label: `Max`,
    })
    return options
  }, [type])
  return (
    <Layout title={title}>
      <Box display="flex" flexDirection="column" flex={1}>
        <Box>
          <Container>
            <Box textAlign="center" py={2}>
              <Typography component="h1" variant="h2">
                {title}
              </Typography>
            </Box>
            <Grid container py={1}>
              <FormInput>
                <InputLabel>Location</InputLabel>
                <FormControl>
                  <InputLabel>Choose Location</InputLabel>
                  <Select
                    name="location"
                    value={location?.toLowerCase()}
                    label="Choose Location"
                    onChange={(e) => {
                      window.dataLayer?.push({
                        event: `search`,
                        value: e.target.value,
                      })
                      window.location.href = listingsPath({
                        townName: e.target.value,
                        type,
                      })
                    }}
                  >
                    <MenuItem value="">All</MenuItem>
                    {locations.map(({ name }, i) => (
                      <MenuItem
                        key={`location-${i}-${name}`}
                        value={name.toLowerCase()}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormInput>
              <FormInput>
                <InputLabel>Price</InputLabel>
                <ButtonGroup fullWidth>
                  <FormControl>
                    <InputLabel>Min</InputLabel>
                    <Select
                      name="price"
                      value={filter.price.gte}
                      onChange={(e) => handleChange(e, `gte`)}
                      label="Min"
                    >
                      {lowOptions.map(({ value, label }) => (
                        <MenuItem value={value} key={`low-${value}`}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Max</InputLabel>
                    <Select
                      name="price"
                      value={filter.price.lte}
                      onChange={(e) => handleChange(e, `lte`)}
                      label="Max"
                    >
                      {highOptions.map(({ value, label }) => (
                        <MenuItem value={value} key={`low-${value}`}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ButtonGroup>
              </FormInput>
              <FormInput>
                <InputLabel>Bedrooms</InputLabel>
                <FormControl>
                  <Select
                    name="bedrooms"
                    onChange={(e) => handleChange(e, `gte`)}
                    value={filter.bedrooms.gte}
                    startAdornment={
                      <InputAdornment position="start">
                        <Beds />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={1}>1+ Beds</MenuItem>
                    <MenuItem value={2}>2+ Beds</MenuItem>
                    <MenuItem value={3}>3+ Beds</MenuItem>
                    <MenuItem value={4}>4+ Beds</MenuItem>
                  </Select>
                </FormControl>
              </FormInput>
              <FormInput>
                <InputLabel>Baths</InputLabel>
                <FormControl>
                  <Select
                    name="bathrooms"
                    onChange={(e) => handleChange(e, `gte`)}
                    value={filter.bathrooms.gte}
                    startAdornment={
                      <InputAdornment position="start">
                        <Bath />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={1}>1+ Baths</MenuItem>
                    <MenuItem value={2}>2+ Baths</MenuItem>
                    <MenuItem value={3}>3+ Baths</MenuItem>
                  </Select>
                </FormControl>
              </FormInput>
              <FormInput>
                <InputLabel>Status</InputLabel>
                <FormControl>
                  <Select
                    displayEmpty
                    name="status"
                    placeholder="All"
                    onChange={(e) => {
                      handleChange(e, `eq`)
                    }}
                    value={filter.status.eq}
                  >
                    <MenuItem value={``}>All</MenuItem>
                    <MenuItem value={`For Sale`}>For Sale</MenuItem>
                    <MenuItem value={`Sold`}>Sold</MenuItem>
                    <MenuItem value={`Pending`}>Pending</MenuItem>
                  </Select>
                </FormControl>
              </FormInput>
            </Grid>
          </Container>
        </Box>
        <ListingsWrapper bgcolor="primary.light" py={4}>
          <Container>
            {listings.length ? (
              <>
                <Grid container spacing={1}>
                  {listings.map(({ id, ...listing }) => (
                    <Grid item xs={12} sm={4} key={id}>
                      <ListingPreview {...listing} />
                    </Grid>
                  ))}
                </Grid>
                {shouldShowFilterReset && (
                  <Box textAlign="center" my={2}>
                    <Link href="#" onClick={resetFilter}>
                      Reset Filters
                    </Link>
                  </Box>
                )}
              </>
            ) : (
              <Typography align="center">
                No results found.
                <br />
                <br />
                {location && (
                  <Link href={listingsPath({ type })}>View all properties</Link>
                )}
                <br />
                {shouldShowFilterReset && (
                  <Link href="#" onClick={resetFilter}>
                    Reset Filters
                  </Link>
                )}
              </Typography>
            )}
          </Container>
          {totalCount > limit && (
            <Container>
              <Box display="flex" justifyContent="flex-end" py={2}>
                <IconButton
                  disabled={page <= 0}
                  onClick={() => setPage(page - 1)}
                >
                  <LeftArrow />
                </IconButton>
                {totalCount > limit && (
                  <IconButton
                    disabled={
                      totalCount < limit ||
                      page === Math.ceil(totalCount / limit) - 1
                    }
                    onClick={() => setPage(page + 1)}
                  >
                    <RightArrow />
                  </IconButton>
                )}
              </Box>
            </Container>
          )}
        </ListingsWrapper>
      </Box>
    </Layout>
  )
}

export const PageQuery = graphql`
  query ListingsPage($type: String!) {
    locations: allContentfulNeighborhoodGuideTown(sort: { fields: name }) {
      nodes {
        name
      }
    }
    listings: allContentfulListing(
      filter: { type: { eq: $type }, active: { eq: true } }
    ) {
      nodes {
        ...ListingPreview
      }
      totalCount
    }
  }
`
export default Listings
