const slugify = require(`slugify`)

module.exports = ({ townName, type }) => {
  const typeName = type === `For Sale` ? `sale` : `rent`
  if (!townName) {
    return `/property-for-${typeName}-dublin`
  }
  return `/property-for-${typeName}-in-${slugify(townName, {
    lower: true,
  })}-county-dublin`
}
